import React from 'react'
import { Link } from 'react-router-dom'

const PrimaryFooter = () => {
  return (
  <>   
  
  
  <div>
            {/* rts footer three area start */}
            <div className="rts-footer-area footer-three footer-four rts-section-gapTop footer-bg-2" id='f-contact'>
                <div className="container pb--100 pb_sm--40">
                    <div className="row g-5">
                        <div className="col-xl-4 col-lg-6">
                            <div className="footer-three-single-wized left">
                            <div className="wized-title">
                                    <h5 className="title"> About us</h5>
                <img src="assets/images/footer/under-title.png" alt="finbiz_footer" />
                                </div>
                                <p className="disc">
                                Planetronix has evolved into a dependable ally across a wide range of critical sectors. We're discussing sectors such as Military and Defence, Aerospace, Government and Public Sector Units, Indian Railways, and Healthcare. This extensive coverage highlights their versatility and expertise.
                                </p>
                                <ul className="social-three-wrapper">
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-linkedin-in" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* footer three mid area */}
                        <div className="col-xl-5 col-lg-6">
 <div className="footer-one-single-wized">
                                <div className="wized-title">
                                    <h5 className="title">Quick Links</h5>
                <img src="assets/images/footer/under-title.png" alt="finbiz_footer" />
                                </div>
                                <div className="quick-link-inner">
                                    <ul className="links">
                                        <li>
                                            <Link to={'/'}> <i className="far fa-arrow-right" /> Home </Link>
                                        </li>
                                        <li>
                                            <Link to={'/about-us'}> <i className="far fa-arrow-right" /> About us </Link>
                                        </li>
                                        <li>
                                            <Link to={'/products'}>
                                                <i className="far fa-arrow-right" /> Products
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/industries'}>
                                                <i className="far fa-arrow-right" /> Industries
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/privacy-policy'}>
                                                <i className="far fa-arrow-right" /> Privacy Policy
                                            </Link>
                                        </li>
                                    </ul>
                                    <ul className="links margin-left-70">
                                        <li>
                                            <Link to={'/disclaimer'}>
                                                <i className="far fa-arrow-right" /> Disclaimer
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'#'}>
                                                <i className="far fa-arrow-right" /> Careers
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/partners'}>
                                                <i className="far fa-arrow-right" />
                                                Partners
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/customers'}>
                                                <i className="far fa-arrow-right" />
                                                Customers
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={'/customer-support'}>
                                                <i className="far fa-arrow-right" />
                                                Customer Support
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
      
                        </div>
                        {/* footer three mid area ENd */}
                        <div className="col-xl-3 col-lg-6">
                          
                        <div className="footer-three-single-wized mid-left">
                        <div className="wized-title">
                                    <h5 className="title"> Contact us </h5>
                <img src="assets/images/footer/under-title.png" alt="finbiz_footer" />
                                </div>
                                        <div className="body">
                                            <div className="info-wrapper">
                                            <p className='disc mb-0 text-white'> Planetronix Consulting Private Limited  </p> 
                                            <div className="single">
                                                    <ul className="icon">
                                                        <li>
                                                            <i className="fas fa-map-marker-alt" />
                                                        </li>
                                                    </ul>
                                                    <div className="info">
                                                        <span> Address </span>
                                                        <a href="#">
                                                       J-61, Sriniwas Puri, New Delhi 110065 India   
                                                        </a>
                                                    </div>
                                                </div>



                                                <div className="single">
                                                    <ul className="icon">
                                                        <li>
                                                            <i className="fas fa-phone-alt" />
                                                        </li>
                                                    </ul>
                                                    <div className="info">
                                                        <span>Call Us</span>
                                                        <a href="#">+91 93103 85466 </a>
                                                    </div>
                                                </div>
                                                <div className="single">
                                                    <ul className="icon">
                                                        <li>
                                                            <i className="far fa-envelope" />
                                                        </li>
                                                    </ul>
                                                    <div className="info">
                                                        <span> Mail us</span>
                                                        <a href="#"> info@planetronix.com </a>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className="disc text-center ptb--25">
                                    Planetronix - &copy; 2024. All rights reserved | Designed & Developed by <Link to={'https://1technologies.in/'}><b> 1Technologies </b>  </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts footer three area end */}

        </div>

  </>
  )
}

export default PrimaryFooter