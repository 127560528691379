import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const InsuranceServices = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Insurance Services' }
    ];
  return (
   <> 
   
   <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Charter Service" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/insurance-services.jpg" alt="Insurance Services" />
                    </div>
                   </div>  
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <h4 className="title"> Insurance Services </h4>
                    <p className='desc'>  <b> "Fly with Confidence". </b> At P.A.S. we provide tailored aviation insurance solutions to keep you protected in the sky. Whether you own a small plane or a large fleet, you fly commercially or privately we provides flexible insurance options tailored to your needs. 
                        Reach out today for a customized quote!   </p>
                    
                </div>  
            </div>
        </div>
    </div>
</div>




{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}

<CallToActionThree />

<PrimaryFooter />
</div>  
   
   </>
  )
}

export default InsuranceServices