import React from 'react'

const Home = () => {
  return (
   <>  
<section className='underconstructionbg'> 

<div className='container'> 
    <div className='row'> 
<div className='col-xl-12 col-lg-12 underconstruction'>   
 <h1>  Planetronix Consulting Private Limited </h1> 
   <h2>  Your Journey, Our Mission   </h2>   

      <h3>  Website Under Construction   </h3>  
<img src='https://planetronix.com/assets/images/aviation-underconstruction.jpg' alt='aviation' />

   <p>  We're currently enhancing our digital wings to provide you with an even better experience. Our website is undergoing a major upgrade to bring you: </p>
   <ul> 
    <li>  Enhanced Various Sector Information </li>
    <li> Comprehensive Service Information </li>
    <li> User-Friendly Interface </li>

    </ul> 

<h4> While We're in transition phase : </h4>
<h4 className='mb-0'> Contact Us: </h4>
<h4> Feel free to reach out. We're still here to serve you: </h4>
<p> Email:  info@planetronix.com  </p>
<p> Phone: +91- +91 93103 85466  </p>
<h4 className='text-center mb-0 pt-5'>  Our Current Services </h4>
 <p className='text-center'> Even while our website is under construction, our services continue to operate: </p>
<h4 className='text-center pt-5 mb-0'>  Thank You for Your Patience</h4> 
<p className='text-center'> As we gear up for our digital relaunch, your patience is appreciated. We promise an experience that matches the thrill of flight. </p>

     </div>
    </div>
     </div>

     </section>
   
   </>
  )
}

export default Home