import React, { useState } from 'react';
import HeaderOne from "../components/header/HeaderOne";

import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import Breadcrumb from "../inner/Breadcrumb";

const Careers = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Careers' }
    ];
  return (
    <>  
<div className=''>
<HeaderOne />
<Breadcrumb title="Careers" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">

     <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> Join our team and embark on a rewarding career journey with
                        Planetronix </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                       
                        <p className="disc"> We’re seeking talented individuals who are passionate about making a difference and driving innovation. </p>
                        <p className='desc'> Explore our current openings and take the first step toward a fulfilling career with ample opportunities for growth and development or write us at info@planetronix.com </p>
                        
                    </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}







<CallToActionThree />

<PrimaryFooter />

</div>

    
    </>
  )
}

export default Careers