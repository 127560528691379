import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import MilitaryDefenceBreadcrumb from '../inner/MilitaryDefenceBreadcrumb';

const MilitaryDefence = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Military & Defence' }
    ];
  return (
  <>  
  
  <div className=''>

<HeaderOne />

<MilitaryDefenceBreadcrumb title="Military & Defence" breadcrumbs={breadcrumbs}   />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
          
        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                            <h4 className="title"> Military and Defense </h4>
<p className="disc">   We, at PCPL, have understood that technology can be leveraged in Military and Defense – the most important part of a country. 
                    We have employed professionals who are proficient to deal in modernized arrangements which can cater to the military needs. 
                    Our military and defence services have helped agencies attain efficiency ever since we have incepted.  
                    With the experience that we have at our disposal we can offer the following:
                            </p>
                            <div className="single-banifits">
                                <i className="far fa-check-circle" />
                                <span> The maximum benefit for your current IT frameworks  </span>
                            </div>
                            <div className="single-banifits">
                                <i className="far fa-check-circle" />
                                <span> Application of innovation and improvements to expand your capacity and utility </span>
                            </div>
                            <div className="single-banifits">
                                <i className="far fa-check-circle" />
                                <span> Delivery of solitary data conditions to change tasks </span>
                            </div>
                        </div>



            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                {/* single wizered start */}



                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Industries </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 




            </div>
        </div>

<div className='row my-5'>
    <div className='col-lg-12 col-md-12 col-12'>
        
    <div className="rts-single-wized contact service">
                   
                    <div className="wized-body">
                        <h5 className="title">Need Help? We Are Here To Help You</h5>
                        <Link className="rts-btn btn-primary" to={'#'}>
                            Contact Us
                        </Link>
                    </div>
                </div>
        
          </div>
      </div>
    </div>
</div>
{/* End service details area */}


<CallToActionThree />

<PrimaryFooter />

</div> 
  </>
  )
}

export default MilitaryDefence