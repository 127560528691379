import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const RuggedEthernetSwitches = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Ethernet Switches' }
    ];
  return (
    <>   
    


   <div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Ethernet Switches" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <h4 className="title"> Rugged Ethernet Switches  </h4>

                    <p className="disc">  Planetronix Rugged Ethernet Switches are housed in rugged, lightweight, and compact enclosures, such as transit cases or 19-inch racks with strain-hardened aircraft aluminum, designed to survive extreme environments, extreme temperatures, and high shock and vibration. </p>
                    <p className="disc"> Planetronix ruggedized switches, firewalls, and routers work in extended operating temperature ranges (-40°C to +85°C) offer basic routing protocols and advanced routing protocols support IPv6 routing; and are compatible with industry-standard command line technology. </p>
                </div>  


            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Rugged Computers </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 
                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}








<CallToActionThree />

<PrimaryFooter />
</div>
       
    
    
    
    </>
  )
}

export default RuggedEthernetSwitches