import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const RuggedKvmDisplays = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged KVM Displays' }
    ];
  return (
   <>  
     
<div className=''>
<HeaderOne />
<Breadcrumb title="Rugged KVM Displays" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                <div className="thumbnail">
                <img src='../../assets/images/products/ruggedkvm-display.jpg' alt='Rugged Displays'  />
                    </div>

                    <h4 className="title"> Rugged KVM Displays </h4>
                  
                    <p className="disc"> Amity Rugged KVM Displays and Rackmount Keyboards are built with light weight aircraft grade aluminum with extended operating temperatures of -40°C to +85°C </p>
                    <p className="disc"> Our Rugged KVM Displays include options for anti-reflective and EMI overlays. Our systems high performance military grade controllers are standard with a wide variety of inputs including VGA, DVI-D, HDMI and composite video inputs; virtually any video signal.  </p>
                    <p className="disc"> These KVM Displays are designed for demanding applications and challenging environments for Ground, Naval & Airborne. </p>
                </div>  

            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Rugged Computers </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 
                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}







<CallToActionThree />

<PrimaryFooter />
</div> 
   </>
  )
}

export default RuggedKvmDisplays