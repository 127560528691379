import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const RuggedComputers = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Computers' }
    ];
  return (
    <>   
    
    
    <div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Computers" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/service/aerospace.jpg" alt="aerospace" />
                    </div>
                    <h4 className="title"> Rugged Computers </h4>
                    <p className="disc">
                    Streamline your aviation supply chain with our innovative, end-to-end solutions designed to reduce downtime, enhance efficiency, and improve visibility across the entire value chain. 
                    From spare parts sourcing to predictive maintenance and regulatory compliance, we’ve got you covered.
                    </p>
                </div>  
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Rugged Computers </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 
                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}

<div className="rts-service-details-area">
<div className='container'>
<h4 className="title text-center mb-25"> Solutions </h4>
<div className="row g-5 mt--30 mb--40">
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                               
                                <div className="details">
                                    <h6 className="title"> Inventory Management </h6>
                                    <p className="disc"> Our advanced inventory management system ensures the availability of critical parts when you need them while minimizing excess stock and associated costs. 
                                        Features include real-time inventory tracking, automated reordering, and predictive analytics to forecast future needs.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                              
                                <div className="details">
                                    <h6 className="title"> Sourcing and Procurement </h6>
                                    <p className="disc"> We simplify your procurement process by connecting you with a global network of certified suppliers. 
                                        Our platform ensures compliance with regulatory standards and offers transparency in pricing, lead times, and availability.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                              
                                <div className="details">
                                    <h6 className="title"> Predictive Maintenance </h6>
                                    <p className="disc"> Reduce aircraft downtime with our predictive maintenance tools. 
                                    By analyzing data from various sensors and systems, we predict potential part failures before they occur, helping you avoid costly delays and disruptions.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                                <div className="details">
                                    <h6 className="title"> Compliance and Documentation </h6>
                                    <p className="disc">
                                    Ensure your aviation operations stay compliant with local and international regulations. 
                                    Our platform manages documentation, certifications, and ensures traceability for every component in your supply chain.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>


                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                                <div className="details">
                                    <h6 className="title"> Logistics and Freight Management </h6>
                                    <p className="disc">
                                    Our logistics management solution handles everything from warehousing to international shipping, ensuring that your parts are delivered on time, anywhere in the world. 
                                    We manage relationships with multiple carriers to provide the best rates and delivery times.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                    </div>
      </div>
      </div>





<div className='container'> 
    <div className='row'> 
        
        
        
    <div className="service-detials-step-2 mt--40">
                    <h4 className="title text-center mb-25"> Industries We Serve </h4>
                 
                    {/* stem-area start */}
                    <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">01</p>
                                <h6 className="title"> Airlines </h6>
                                <p className="disc"> Tailored solutions to streamline your fleet maintenance and reduce operational costs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">02</p>
                                <h6 className="title"> Maintenance, Repair & Overhaul (MRO)  </h6>
                                <p className="disc"> Efficient sourcing and inventory management to keep your MRO operations running smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">03</p>
                                <h6 className="title"> Original Equipment Manufacturers (OEMs) </h6>
                                <p className="disc"> Collaborate with us to manage complex global supply chains and ensure timely delivery of critical parts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        
        
         </div>
     </div>

<CallToActionThree />

<PrimaryFooter />
</div>
  </>
  )
}

export default RuggedComputers