import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const CharterService = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Charter Service' }
    ];
  return (
   <>
   
   <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Charter Service" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/charter-service.jpg" alt="General Aviation Support" />
                    </div>
                    <h4 className="title"> Charter Service </h4>
                    <p className="disc"> As an Aviation charter service providers, we play a critical role in offering bespoke travel solutions, emphasizing flexibility, luxury, and efficiency while navigating the complexities of aviation operations and client expectations.
Our Key services & features are outlines as;  </p>
<h5> Services </h5>
<p>  Offers private, on-demand air travel where clients can charter entire aircraft for tailored flight itineraries. </p>
<h5> Aircraft Fleet </h5>
<p>  These include jets, turboprops, and helicopters, varying from light to heavy categories to suit different needs. </p>
<h5>  Clientele: </h5>
<ul> 
<li> Individuals: High-net-worth individuals, celebrities, or executives needing privacy or convenience. </li>
<li> Businesses: Companies requiring quick, flexible travel solutions for teams or to remote locations. </li>
<li> Special Events: Weddings, sports events, or festivals where regular airline services are insufficient. </li>
</ul>
<h5> Our Benefits: </h5>
<ul> 
<li> Customization: Flight routes, timings, and amenities can be adjusted to client specifications. </li>
<li> Efficiency: Bypasses commercial airport hassles, offering direct point-to-point service. </li>
<li> Privacy & Security: Controlled environment for sensitive travel or high-profile individuals. </li>


</ul>
                </div>  
            </div>
        
        </div>
    </div>
</div>
{/* End service details area */}


<div className='container'> 
    <div className='row'>     
    <div className="service-detials-step-2 mt--40">
                    <h4 className="title text-center mb-25"> Industries We Serve </h4>
                    {/* stem-area start */}
                    <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">01</p>
                                <h6 className="title"> Airlines </h6>
                                <p className="disc"> Tailored solutions to streamline your fleet maintenance and reduce operational costs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">02</p>
                                <h6 className="title"> Maintenance, Repair & Overhaul (MRO)  </h6>
                                <p className="disc"> Efficient sourcing and inventory management to keep your MRO operations running smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">03</p>
                                <h6 className="title"> Original Equipment Manufacturers (OEMs) </h6>
                                <p className="disc"> Collaborate with us to manage complex global supply chains and ensure timely delivery of critical parts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        
        
         </div>
    
     </div>





{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}

<CallToActionThree />

<PrimaryFooter />
</div>

   
   </>
  )
}

export default CharterService