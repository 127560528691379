import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import Accordion from 'react-bootstrap/Accordion';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import CallToActionThree from '../components/calltoaction/CallToActionThree';

const PrivacyPolicy = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Privacy Policy' }
    ];
  return (
 <>   
 
 <div className=''>
<HeaderOne />
<Breadcrumb title="Privacy Policy" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <h4 className="title"> Privacy Policy </h4>
                    <p className="disc">   </p>

    <p> At Planetronix Consulting Private Limited we consider the privacy of our visitors to be extremely important. This privacy policy document describes in detail the types of personal information is collected and recorded by Planetronix.com and how this data it is used. No effort is made to identify individuals without their knowledge. </p>
    <p> This policy does not apply to the practices of companies that Planetronix.com does not own or control, or to people that Planetronix.com does not employ or manage. </p>
    <h4> Log Files </h4>
    <p> Like many other Web sites, Planetronix.com makes use of log files. These files merely logs visitors to the site - usually a standard procedure for hosting companies and a part of hosting services's analytics. The information inside the log files includes IP addresses, browser type, Internet Service Provider (ISP), date/time stamp, referring/exit pages, and possibly the number of clicks. This information is used to analyze trends, administer the site, track user's movement around the site, and gather demographic information. IP addresses and other such information are not linked to any information that is personally identifiable. </p>
    <h4> Cookies </h4>
    <p> Planetronix.com uses cookies to store information about visitors' preferences only in order to improve user experience using our site. User can choose if he wants to accept or reject all the cookies by modifying your browser preferences. It is possible that by rejecting cookies some functions of the site will not function properly.</p>
    <h4> Ad Networks</h4>
    <p> We use Google AdSense and they serve ads on our site based on a user's prior visits to our website. Google's use of the DoubleClick cookie enables it and its partners to serve ads to your users based on their visit to our sites and/or other sites on the Internet. Users may opt out of the use of the DoubleClick cookie for interest-based advertising by visiting Ads Settings. Alternatively, users can opt out of a third-party vendor's use of cookies for interest-based advertising by visiting aboutads.info.</p>
    <p> Planetronix.com may also use other advertising networks (other than Google AdSense), whose privacy policies will always be listed on their respective sites. Most of these companies belong to the Network Advertising Initiative. Our visitors may opt-out of behavioral advertising methods by visiting networkadvertising.org. </p>
    <h4> Google Analytics </h4>
    <p> Google Analytics is a free service provided by Google Inc. for gathering statistical information about visitors to a website. This information generally includes IP addresses, geographical location of the IP address, pages visited, how the visitor arrived at the webpage and computer information, such as the operating system and browser used. Google Analytics is implemented through a cookie, which is placed on the visitor's computer coupled with code in the webpage, which collects visitor data and relays it to Google's servers for processing. </p>
    <p> Our visitors can opt out of Google Analytics by using the opt-out service provided by Google: Google Analytics Opt-out Browser Add-on. </p>
    <h4> Data Security: </h4>
    <p> To prevent unauthorized access, maintain data accuracy and ensure the correct use of information, we have put in place appropriate physical, electronic and managerial procedures to safeguard and secure the information we collect online. </p>
<h4> Unsolicited Communication </h4>
<p> Planetronix.com will not use your e-mail address in order to send you any kind of unsolicited or promotional mail. It is your choice whether you decide to receive newsletters or not from us. You can always choose to unsubscribe from our newsletter services by visiting a link in footer of every email. </p>
<h4> Children's Information: </h4>
<p> We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity. Planetronix.com does not knowingly collect any personally identifiable information from children under the age of 13. If a parent or guardian believes that Planetronix.com has in its database the personally identifiable information of a child under the age of 13, please contact us immediately (using the contact form) and we will use our best efforts to promptly remove such information from our records. </p>
<h4> Consent: </h4>
<p> By using our website, you hereby consent to our privacy policy and agree to its terms.</p>
<h4> Update:  </h4>
<p> Any changes we may make to our Privacy Policy in the future will be posted on this page and. Users subscribed to our newsletter will be notified by e-mail. </p>
<h4> Disclaimer of warranties </h4>
<p> Your use of Planetronix.com is at your own risk. This website is provided by Planetronix Consulting Private Limited on an “as is” and “as available” basis. To the full extent permissible by applicable law, cyber site disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose and non-infringement. You acknowledge that any warranty that is provided in connection with any of the products or services made available on or through Planetronix.com is provided solely by the owner, advertiser or manufacturer of that product and/or service and not by Planetronix Consulting Private Limited </p>
<p>  Planetronix Consulting Private Limited makes no representations or warranties of any kind, express or implied, as to the operation of the website or the information, content, materials, or products on the website, including that </p>
<p> Planetronix.com and its services will meet your requirements </p>
<p> Planetronix.com will be uninterrupted, timely, secure or error-free </p>
<p>  The quality of any products, services, information or other material purchased or obtained by you through Planetronix.com will meet your expectations, be reliable or accurate, and errors in the software will be corrected. </p>
<p>  Your sole remedy for dissatisfaction with Planetronix.com, site-related services and/or content or information contained within the site is to stop using the site and/or its services. Any material downloaded or otherwise obtained through the use of Planetronix.com is done at your own discretion and risk and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material. </p>
<h2> Links </h2>
<h4> Linked Sites:  </h4>
<p> For your convenience, Planetronix.com provides links to other websites not in our control (“Favorite Links”). You acknowledge that Planetronix Consulting Private Limited does not endorse these linked Sites (even if they pop up in a frame) or any links contained in these linked Sites. In addition, Planetronix Consulting Private Limited is not responsible for any content or the privacy policies of these linked Sites or for any data collected on you at these linked sites. </p>
<h4> Third-Party Merchants and Advertisers </h4>
<p> You acknowledge that Planetronix Consulting Private Limited Does not control the performance of merchants or advertisers that appear on Planetronix.com. Therefore, if you choose to purchase a product or service, you release us from any and all claims you may have regarding the third party’s failure to deliver or perform. We use other third-party advertising companies to serve ads when you visit our website. These companies may use information (not including your name, address, email address or telephone number) about your visits to this and other websites in order to provide advertisements on this site and other sites about goods and services that may be of interest to you. </p>
<h4> Third Party Cookies </h4>
<p>  In the course of serving advertisements to this site, our third-party advertiser may place or recognize a unique “cookie” on your browser. </p>
<h4>  Void where prohibited  </h4>
<p>  Although Planetronix.com is accessible worldwide, not all products or services discussed or referenced on Planetronix.com are available to all persons or in all geographic locations or jurisdictions. Planetronix.com reserves the right to limit the provision of any product or service to any person, geographic area or jurisdiction it so desires, in its sole discretion and to limit the quantities of any such product or service that it provides. Any offer for any product or service made on Planetronix.com is void where prohibited. </p>
<h4>  Limitation of liability  </h4>
<p>  In no event shall Planetronix Consulting Private Limited be liable for any direct, indirect, incidental, special, punitive, consequential damages, or any damages whatsoever, including, but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from </p>
<ul>
  <li> The use or the inability to use our services or access content </li>
  <li> The cost of procurement of substitute goods and services resulting from transactions entered into through or from Planetronix.com </li>
  <li> The unauthorized access to or alterations of your transmissions or data </li>
  <li> Statements or conduct of any third party on the service, or </li>
  <li> Any other matter relating to the service, even if Planetronix Consulting Private Limited has been advised of the possibility of damages. </li>
</ul>
<h4> Indemnification:  </h4>
<p>  You agree to indemnify, defend and hold Planetronix Consulting Private Limited harmless from all claims, damages and expenses (including attorney’s fees) made by any third party arising out of your content, your use of Planetronix.com, your connection to Planetronix.com, your violation of this Agreement, our Terms of Use or our Privacy Policy, and the development, operation, maintenance, use and contents of your website. </p>
<h4>  Financial transactions and taxes  </h4>
<p> If you make any financial transactions on Planetronix.com, including paying for network services fees, advertising banners, product placements, products or services, you may be asked for credit card or other payment information. You agree that all information you provide is accurate, complete and current and that you will pay all charges owed, including any applicable taxes. You agree that you are solely responsible for any personal income reporting and tax payments required of you by applicable government authorities. </p>
<h4>  Termination </h4>
<p>  Planetronix Consulting Private Limited reserves the right to discontinue in whole or in part any portion of Planetronix.com services or programs with or without notice. </p>
<h4> Independent investigation </h4>
<p> You acknowledge that you have read this agreement and agree to all its terms and conditions. You, understand that we may at any time (directly or indirectly) solicit member referrals on terms that may differ from those contained in this user agreement. You have independently evaluated the desirability of participating in Planetronix.com or its programs and are not relying on any representation, guarantee or statements other than as set forth in this agreement. </p>






                </div>  



            </div>
         
        </div>
    </div>
</div>
{/* End service details area */}




{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


<CallToActionThree />
<PrimaryFooter />
</div>
 
 </>
  )
}

export default PrivacyPolicy