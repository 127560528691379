import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const CustomerSupport = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Customer Support' }
    ];
  return (
   <>     
   
   <div className=''>
<HeaderOne />
<Breadcrumb title="Customer Support" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <h4 className="title"> Disclaimer </h4>
                    <p> If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at info@planetronix.com. </p>
    <p> All the information displayed, transmitted or carried by Planetronix.com and its related websites including, but not limited to, directories, guides, news articles, opinions, reviews, text, photographs, images, illustrations, profiles, audio clips, video clips, trademarks, service marks and the like, collectively the “Content”, are protected by the copyright and other intellectual property laws. </p>
    <p>  The Content is owned by Planetronix.com, its affiliates or third party licensors. You may not modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way commercially exploit any of the Content. You agree to abide by all copyright notices and restrictions attached to any Content accessed through the Planetronix.com website and not to alter the content in any way. </p>
    <p>  All the information on this website is published in good faith and for general information purpose only. Website Name does not make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website (Planetronix.com), is strictly at your own risk. Planetronix.com will not be liable for any losses and/or damages in connection with the use of our website. </p>
    <p>  From our website, you can visit other websites by following hyperlinks to such external sites. While we strive to provide only quality links to useful and ethical websites, we have no control over the content and nature of these sites. These links to other websites do not imply a recommendation for all the content found on these sites. Site owners and content may change without notice and may occur before we have the opportunity to remove a link which may have gone ‘bad'. </p>
    <p> Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information. </p>
    <h3> Consent:  </h3>
    <p> By using our website, you hereby consent to our disclaimer and agree to its terms. </p>
    <h3> Update </h3>
    <p> Should we update, amend or make any changes to this document, those changes will be prominently posted here. </p>
    <h3>  Permission of Use </h3>
    <p> You may take a single copy of the Content displayed on the Planetronix.com for personal, non-commercial use only, provided that you do not remove any trademarks, copyright and any other notice contained in such Content. You shall not archive or retain any Content in any form without written permission.  </p>
    <h3> General Disclaimer and Limitation of Liability </h3>
    <ul>
<li>  The Planetronix.com web site contains facts, views, opinions, statements and recommendations of users of the Planetronix.com website, advertisers, third party information providers and organizations.  </li>
<li>  The Planetronix.com web site does not represent or endorse the accuracy, completeness or reliability of any advice, opinion, statement or other information displayed, uploaded or distributed through the web site. You acknowledge that any reliance upon any such opinion, advice, statement or information shall be at your sole risk.  </li>
<li>  Planetronix.com makes no warranty or representation whatsoever regarding the web site or any content, advertising, services or products provided through or in connection with the web site.  </li>
<li>  Planetronix.com expressly disclaims any and all warranties, express or implied, including, without limitation:  </li>
<li>  Any warranties as to the availability, accuracy, completeness or content of information, products or services that are part of the Planetronix.com web site.  </li>
<li> And warranties or fitness for a particular purpose, or merchantability or against infringement. In no event will Planetronix.com, its affiliates, licensors, employees, agents or contractors, be liable to you for any damages or losses, including without limitation indirect, consequential, special, incidental or punitive damages resulting from or caused by the Planetronix.com web site, its public posting bulletin boards, its content or any errors or omissions in its content, even if advised of the possibility of such damages.   </li>

    </ul>
          
                </div>  



            </div>
          
        </div>
    </div>
</div>
{/* End service details area */}

<CallToActionThree />
<PrimaryFooter />
</div>
  
   </>
  )
}

export default CustomerSupport