import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import FooterOne from "../components/footer/FooterOne";
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const OurVision = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Our Vision' }
    ];
  return (
  <>  
  
  <div className=''>

<HeaderOne />
<Breadcrumb title="Our Vision" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">



            <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> Our Vision </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                        <p className="disc">
                        To give our clients the most compelling experience possible and build a long term relationship by becoming one of the top IT solution providers worldwide.
   </p>
                                       
                                        </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}




<CallToActionThree />
<PrimaryFooter />

</div>

  
  </>
  )
}

export default OurVision