import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const SupplyChainManagement = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Supply Chain Managment' }
    ];
  return (
    <>   
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Supply Chain Management" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
                    </div>
                   </div>  
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                  
                    <h4 className="title"> Supply Chain Management </h4>
                    <ul>
<li>   Our Supply Chain Management services cater to the unique demands of aviation supply chains, where precision, compliance, and reliability are paramount.  </li>
<li>   We provide robust supply chain management services with an emphasis on timely delivery and quality control for aviation components. Our chain management is geared towards supporting the global production and maintenance of Aerospace companies </li>
<li>  As a SCM service provider we would like to be your valuable aviation partner who are looking to optimize, secure, and grow their supply chain operations. </li>
  </ul>
                   
                </div>  
            </div>
        </div>
    </div>
</div>




{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


<CallToActionThree />

<PrimaryFooter />
</div>
  
    </>
  )
}

export default SupplyChainManagement