import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const Partners = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Partners' }
    ];
  return (
    <>
    
  <div className='' style={{backgroundColor:'#f2f1f6'}}>
<HeaderOne />
<Breadcrumb title="Partners" breadcrumbs={breadcrumbs} />



   {/* start trusted client section */}
   <div className="rts-trusted-client rts-section-gapBottom rts-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className='text-center'> Our Partners Customers  </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="client-wrapper-one">
                            <a href="#">
                                <img src="assets/images/client/barco.png" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/partners/airtel.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/partners/aten-dealer.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/azure.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/belden.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/cisco.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/commvault.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/dlink.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                            <img src="assets/images/partners/eaton.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/emudhra.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/extreme.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/microsoft.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/panasonic.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/polomarconi.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/samsung.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/schneider.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/sify.jpg" alt="business_finbiz" />
                            </a>

                            <a href="#">
                            <img src="assets/images/partners/supermicr.jpg" alt="business_finbiz" />
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            {/* end trusted client section */}



<CallToActionThree />

<PrimaryFooter />
</div>

   
    </>
  )
}

export default Partners