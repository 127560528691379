import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import { Link } from 'react-router-dom';

const Industries = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Industries' }
    ];
  return (
   <>
   
   
   <div className=''>
<HeaderOne />
<Breadcrumb title="Industries" breadcrumbs={breadcrumbs} />



<div className="rts-service-details-area ptb-100">
<div className='container'>
<h4 className="title text-center mb-25 pt-5"> Industries </h4>
<div className="row g-5 mt--30 mb--40">


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/military-defence'}>   <img src='assets/images/industries/military-defence.jpg' alt='Warehouse' />
    <h5> Military & Defence </h5> </Link>
    </div>
</div>

<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/aerospace'}>   <img src='assets/images/industries/aviation.jpg' alt='Aviation' />
    <h5> Aviation </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/government-psu'}>  <img src='assets/images/industries/government-psu.jpg' alt='Government & PSU' />
    <h5> Government & PSU </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/railways'}>  <img src='assets/images/industries/railways.jpg' alt='Railways' />
    <h5> Railways </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
   <Link to={'/healthcare'}>  <img src='assets/images/industries/healthcare.jpg' alt='Healthcare' />
    <h5> Healthcare </h5> </Link>
    </div>
</div>

</div>
      </div>
      </div>







<CallToActionThree />

<PrimaryFooter />
</div>
   
   </>
  )
}

export default Industries