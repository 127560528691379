import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const RuggedDisplays = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Displays' }
    ];
  return (
    <>   
    
<div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Displays" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                <div className="thumbnail">
                <img src='../../assets/images/products/rugged-displays.jpg' alt='Rugged Displays'  />
                    </div>

                    <h4 className="title"> Rugged Displays </h4>
                    <p className="disc"> 5″ – 24″ ruggedized rack mount / panel mount Full HD resolution and high brightness LED Displays. We provide rugged monitors or displays fitted with an optically bonded display to reduce refraction and glare, increase durability against vandalism, improve touchscreen accuracy and prevent condensation. </p>
                    <p className="disc">  Designed to exceed your expectations in any environments like Military, Industrial, and Commercial programs on Sea, Land and in Air. Certified to comprehensive Military and Industrial Standards for added compatibility, hardened security, and undeniable reliability. </p>
                </div>  
<div className='row'> 

    <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 10 Series Rugged Displays </h5>
        <p className="disc mb-0">  MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 10.4″ TFT </td> 
    </tr>
    <tr>
      <th> Resolution	</th>
      <td> 1024 x 768 </td> 
    </tr>
    <tr>
      <th> Brightness	</th>
      <td> 1000nits      </td> 
    </tr>
    <tr>
      <th> Touch Type	</th>
      <td> No Touch </td> 
    </tr>
    <tr>
      <th> Mounting	</th>
      <td> Panel Mount / Console Mount / Rack Mount </td> 
    </tr>
  </tbody>
</table>
</div>
         </div>


         <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 15 Series Rugged Displays </h5>
        <p className="disc mb-0"> MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 15″ TFT Display </td> 
    </tr>
    <tr>
      <th> Resolution	</th>
      <td> 1920 x 1080 </td> 
    </tr>
    <tr>
      <th> Brightness	</th>
      <td> 500nits </td> 
    </tr>
    <tr>
      <th> Touch Type	</th>
      <td> Capacitive Touch </td> 
    </tr>
    <tr>
      <th> Mounting	</th>
      <td> Rack Mount </td> 
    </tr>
  </tbody>
</table>
</div>
 </div>


 <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 15 Series Rugged Displays </h5>
        <p className="disc mb-0"> MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 15″ TFT Display </td> 
    </tr>
    <tr>
      <th> Resolution	</th>
      <td> 1920 x 1080 </td> 
    </tr>
    <tr>
      <th> Brightness	</th>
      <td> 500nits </td> 
    </tr>
    <tr>
      <th> Touch Type	</th>
      <td> Capacitive Touch </td> 
    </tr>
    <tr>
      <th> Mounting	</th>
      <td> Rack Mount </td> 
    </tr>
  </tbody>
</table>
</div>
 </div>


 <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 15 Series Rugged Displays </h5>
        <p className="disc mb-0"> MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 15″ TFT Display </td> 
    </tr>
    <tr>
      <th> Resolution	</th>
      <td> 1920 x 1080 </td> 
    </tr>
    <tr>
      <th> Brightness	</th>
      <td> 500nits </td> 
    </tr>
    <tr>
      <th> Touch Type	</th>
      <td> Capacitive Touch </td> 
    </tr>
    <tr>
      <th> Mounting	</th>
      <td> Rack Mount </td> 
    </tr>
  </tbody>
</table>
</div>
 </div>
     </div>
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Rugged Computers </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/railways'}>
                                    Railways <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/healthcare'}>
                                    Healthcare <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                    </div>
                </div> 
                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}







<CallToActionThree />
<PrimaryFooter />
</div>

    </>
  )
}

export default RuggedDisplays