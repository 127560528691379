import React from 'react'

function BrandOne() {
    return (
        <div>
            {/* start trusted client section */}
            <div className="rts-trusted-client rts-section-gapBottom rts-section-gapTop">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className='text-center'> Our Trusted Customers  </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="client-wrapper-one">
                            <a href="#">
                                <img src="assets/images/client/barco.png" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/beml.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/hal.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/fortis.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/bharat-electronics.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/indian-railway.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/jindal-steel.jpg" alt="business_finbiz" />
                            </a>
                            <a href="#">
                                <img src="assets/images/client/ntpc.jpg" alt="business_finbiz" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* end trusted client section */}

        </div>
    )
}

export default BrandOne