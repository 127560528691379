import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import { Link } from 'react-router-dom';

const Products = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Products' }
    ];
  return (
    <>
  <div className=''>
<HeaderOne />
<Breadcrumb title="Products" breadcrumbs={breadcrumbs} />



<div className="rts-service-details-area ptb-100">
<div className='container'>
<h4 className="title text-center mb-25 pt-5"> Our Products </h4>
<div className="row g-5 mt--30 mb--40">


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/rugged-displays'}>   <img src='assets/images/rugged-display.jpg' alt='Warehouse' />
    <h5> Rugged Displays </h5> </Link>
    </div>
</div>

<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/rugged-kvm-displays'}>   <img src='assets/images/rugged-kvm-display.jpg' alt='Aviation' />
    <h5> Rugged KVM Displays </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/rugged-panel-pcs'}>  <img src='assets/images/rugged-panel-pc.jpg' alt='Government & PSU' />
    <h5> Rugged Panel PCs </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
<Link to={'/rugged-workstations'}>  <img src='assets/images/rugged-workstations.jpg' alt='Railways' />
    <h5> Rugged Workstations </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
   <Link to={'/rugged-servers'}>  <img src='assets/images/rugged-servers.jpg' alt='Healthcare' />
    <h5> Rugged Servers </h5> </Link>
    </div>
</div>


<div className="col-lg-4 col-md-4 col-12">
<div className='industries_box'>
   <Link to={'/rugged-ethernet-switches'}>  <img src='assets/images/ethernet-switches.jpg' alt='Healthcare' />
    <h5> Rugged Ethernet Switches </h5> </Link>
    </div>
</div>



</div>
      </div>
      </div>


<CallToActionThree />
<PrimaryFooter />
</div>
  
    </>
  )
}

export default Products