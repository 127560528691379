import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";

import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const OurMission = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Our Mission' }
    ];
  return (
   <>  
<div className=''>
<HeaderOne />
<Breadcrumb title="Our Mission" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">

            <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> Our Mission </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                        <p className="disc"> To offer effective services without relinquishing quality and to fulfil the needs of the client by developing their business, holistically. We intend to help the business accomplish a remarkable exhibition online through our imaginative approach. 
                        </p>
                    
                    </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}



<CallToActionThree />

<PrimaryFooter />

</div>


   
   </>
  )
}

export default OurMission