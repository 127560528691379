import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const ComponentRepair = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Components Repair & Rental Services' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Components Repair & Rental Services" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
                    </div>
                   </div>  
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                   <h4 className="title"> Components Repair & Rental Services </h4>
                   <p> Elevate Your Aviation Operations with Premier Repair and Rental Service. We are offering sleek, high-tech repair shop with aviation components in focus. </p>
                   <h5> Aviation Components Repair Services: </h5>
                    <ul>
<li> <b>  Specialization: </b> Focused on facilitating repairing a wide range of aviation components like engines, avionics, and hydraulics.  </li>
<li> <b>  Quality Assurance: </b> The Repair Station employs strict testing protocols to ensure components meet or exceed OEM standards post-repair. </li>
<li> <b> Efficiency: </b>  Quick turnaround times to minimize aircraft downtime, often using advanced diagnostic tools. </li>
<li> <b>  Certification: </b> Operations adhere to aviation regulatory bodies like FAA or EASA for compliance and safety. </li>

                    </ul>  
                    <h5> Aviation Components Rental Services: </h5> 
                    <ul>

                  <li> <b>  Range: </b> Offers various components for rent, including hard-to-find or obsolete parts for various aircraft. </li>    
                  <li><b>  Cost-Effective: </b> Helps reduce the capital investment in purchasing less frequently used or backup components. </li>     
                  <li><b>  Flexibility: </b> Short-term or long-term rental options tailored to operational needs, with options for emergency rentals. </li>   
                  <li> <b>  Maintenance Included: </b> Ensured that rented components are maintained to high standards, with repair services available if needed during the rental period. </li>   

                    </ul>
                </div>  
            </div>     
        </div>
    </div>
</div>
{/* End service details area */}



{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default ComponentRepair