import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import BrandOne from '../components/brand/BrandOne';


const Customers = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Customers' }
    ];
  return (
  <>
  <div className='' style={{backgroundColor:'#f2f1f6'}}>

<HeaderOne />
<Breadcrumb title="Our Customers" breadcrumbs={breadcrumbs} />

<BrandOne />


<CallToActionThree />

<PrimaryFooter />
</div>

  
  
  
  </>
  )
}

export default Customers